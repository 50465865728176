var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-card',{staticClass:"ma-0 pa-0",staticStyle:{"border-radius":"10px"},attrs:{"flat":"","outlined":""}},[_c('v-col',{staticClass:"font-weight-bold mx-0 mt-2",attrs:{"cols":"12"}},[_c('v-row',{staticClass:"d-flex flex-wrap px-2 align-center"},[_c('v-chip',{staticClass:"text-capitalize mr-2 white--text",attrs:{"dark":"","color":_vm.statusColors[_vm.request.status].color}},[_vm._v(_vm._s(_vm.request.status))]),_c('p',{staticClass:"my-0",staticStyle:{"font-weight":"bold !important"}},[_vm._v(" "+_vm._s(_vm.formatDate(_vm.request.dueDate))+" ")]),(
                    _vm.request.readyForPricing &&
                        _vm.request.status == 'inProgress'
                )?_c('v-icon',{staticClass:"ml-1",attrs:{"color":"red"}},[_vm._v("mdi-flag")]):_vm._e()],1),_c('v-row',{staticClass:"d-flex px-3 align-center mt-5"},[_c('p',{staticClass:"my-0"},[_vm._v("ID: "+_vm._s(_vm.request.number))]),(
                    (_vm.request.readyForPricing &&
                        _vm.request.status == 'inProgress') ||
                        _vm.request.status != 'inProgress'
                )?_c('v-btn',{staticClass:"ml-1 my-0",attrs:{"small":"","icon":"","color":"primary"},on:{"click":_vm.openAssignBuyer}},[_c('v-icon',[_vm._v(" mdi-clipboard-account ")])],1):_vm._e(),_c('v-btn',{staticClass:"ml-1 my-0",attrs:{"small":"","icon":"","color":"primary"},on:{"click":_vm.openRequest}},[_c('v-icon',[_vm._v(" mdi-eye ")])],1)],1),_c('v-row',{staticClass:"d-flex flex-wrap font-weight-regular mb-0 mt-4 px-1"},[_c('p',{staticClass:"my-0",staticStyle:{"margin-left":"8px"},attrs:{"color":"primary","font-weight":"bold"}},[_vm._v(" "+_vm._s('TYPE: ' + _vm.request.type)+" ")]),_c('ul',_vm._l((_vm.request.items),function(item,index){return _c('li',{key:index,staticClass:"my-0",staticStyle:{"font-size":"12px"}},[_vm._v(" "+_vm._s(item.description)+" ("+_vm._s(item.quantity)+") ")])}),0)])],1),_c('v-dialog',{attrs:{"persistent":"","fullscreen":""},model:{value:(_vm.openForm),callback:function ($$v) {_vm.openForm=$$v},expression:"openForm"}},[(_vm.openForm)?_c('PurchaseRequest',{attrs:{"request":_vm.request,"originalRequest":_vm.request,"createForm":_vm.createForm,"projectId":_vm.projectId},on:{"closeDialog":function($event){;(_vm.openForm = false), _vm.closeRequest()}}}):_vm._e()],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }