<template>
    <v-card flat style="border-radius: 10px" outlined class="ma-0 pa-0">
        <v-col cols="12" class="font-weight-bold mx-0 mt-2">
            <v-row class="d-flex flex-wrap px-2 align-center">
                <v-chip
                    class="text-capitalize mr-2 white--text"
                    dark
                    :color="statusColors[request.status].color"
                    >{{ request.status }}</v-chip
                >
                <p class="my-0" style="font-weight: bold !important">
                    {{ formatDate(request.dueDate) }}
                </p>
                <v-icon
                    v-if="
                        request.readyForPricing &&
                            request.status == 'inProgress'
                    "
                    class="ml-1"
                    color="red"
                    >mdi-flag</v-icon
                >
            </v-row>
            <v-row class="d-flex px-3 align-center mt-5">
                <p class="my-0">ID: {{ request.number }}</p>
                <v-btn
                    small
                    icon
                    color="primary"
                    class="ml-1 my-0"
                    @click="openAssignBuyer"
                    v-if="
                        (request.readyForPricing &&
                            request.status == 'inProgress') ||
                            request.status != 'inProgress'
                    "
                >
                    <v-icon>
                        mdi-clipboard-account
                    </v-icon>
                </v-btn>
                <v-btn
                    small
                    icon
                    color="primary"
                    class="ml-1 my-0"
                    @click="openRequest"
                >
                    <v-icon>
                        mdi-eye
                    </v-icon>
                </v-btn>
            </v-row>
            <v-row class="d-flex flex-wrap font-weight-regular mb-0 mt-4 px-1 ">
                <p
                    class="my-0"
                    color="primary"
                    style="margin-left: 8px;"
                    font-weight="bold"
                >
                    {{ 'TYPE: ' + request.type }}
                </p>
                <ul>
                    <li
                        v-for="(item, index) of request.items"
                        :key="index"
                        class="my-0"
                        style="font-size: 12px"
                    >
                        {{ item.description }} ({{ item.quantity }})
                    </li>
                </ul>
            </v-row>
        </v-col>
        <v-dialog persistent fullscreen v-model="openForm">
            <PurchaseRequest
                v-if="openForm"
                :request="request"
                :originalRequest="request"
                :createForm="createForm"
                :projectId="projectId"
                @closeDialog=";(openForm = false), closeRequest()"
            />
        </v-dialog>
    </v-card>
</template>

<script>
import moment from 'moment'
import PurchaseRequest from '@/components/Purchasing/PurchaseRequest.vue'

export default {
    name: 'PurchasesTask',
    props: {
        request: {
            type: Object,
            default: () => ({}),
        },
    },
    components: {
        PurchaseRequest,
    },
    data: () => ({
        loding: false,
        statusColors: Object.freeze({
            inProgress: {
                text: 'inProgress',
                color: 'primary',
            },
            onHold: {
                text: 'onHold',
                color: 'green',
            },
            pricing: {
                text: 'pricing',
                color: '#99c0e1',
            },
            preApproval: {
                text: 'preApproval',
                color: '#FF9800',
            },
            approval: {
                text: 'approval',
                color: '#27BC4F',
            },
            purchasing: {
                text: 'purchasing',
                color: 'primary',
            },
            inTransit: {
                text: 'inTransit',
                color: '#FF9800',
            },
            closed: {
                text: 'closed',
                color: '',
            },
        }),
        originalRequest: {},
        selectedRequest: {},
        createForm: false,
        projectId: null,
        openForm: false,
        constDueDate: {},
    }),
    methods: {
        openAssignBuyer() {
            this.$emit('openAssignBuyer', this.request.id)
        },
        formatDate(seconds) {
            return moment.unix(seconds).format('MMM DD')
        },
        openRequest() {
            this.constDueDate = this.request.dueDate
            this.projectId = this.request.project.id
            this.request.dueDate = moment
                .unix(this.request.dueDate.seconds)
                .format('YYYY-MM-DD')
            this.openForm = true
        },
        closeRequest() {
            this.request.dueDate = this.constDueDate
            this.constDueDate = {}
        },
    },
}
</script>

<style></style>
